.ant-modal {
  width: min-content !important;

  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.95);
  }
}

.ant-modal-close {
  z-index: 30;
}

.ant-modal-close:hover {
  border: 0px;
}

.ant-picker-input input {
  font-size: 11px;
}

.ant-modal-title {
  text-align: center;
}

.pending-order-modal {
  .ant-modal-content {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
  }
  .ant-modal-header {
    border-radius: 10px;
    border: 0;
    padding-bottom: 0;
  }
  .ant-modal-title {
    padding-top: 40px;
    color: $primary;
    font-weight: 700;
    font-size: 14px;
  }
  .modal-icon {
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $primary;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-footer {
    border: 0;
  }
}
// Collapse package options
.ant-collapse-borderless {
  background-color: #fff;
}

.ant-collapse-header-text {
  font-family: $txtFamily;
  font-weight: bolder;
  font-size: $large;
  padding-top: 5px;
}

// Buyer Form
.ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

#buyerInfoForm {
  padding: 0 !important;
}

.ant-select-selection-item,
.ant-select-item-option-state,
.ant-select-item-option-content {
  font-family: $txtFamily;
  font-weight: bolder;
  font-size: $large;
}

// Input
.ant-picker {
  border-radius: 5px;
  // border: solid 1px #CBD5E1;
  border: solid 1px #cbd5e1;
  padding: 12px;
}

.ant-picker-input input {
  font-family: $txtFamily;
}

// Input (Disable Arrow for number type)
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buyer-form .ant-collapse-header,
.traveller-form .ant-collapse-header {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.buyer-form .ant-collapse-content > .ant-collapse-content-box,
.traveller-form .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.prod-details .ant-select-selection-item {
  font-size: 12px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}

button[disabled],
input[disabled] {
  cursor: not-allowed !important;
}

// Select
.ant-select {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.375rem;
  background-color: #fff;
  border: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-right: 0;
}

//Dropdown Select
.ant-space-item {
  font-size: 12px;
  position: relative;
  bottom: 1px;
}

.shoe-type .anticon.anticon-down {
  color: rgba(0, 0, 0, 0.25) !important;
}

.package-option .anticon.anticon-down {
  color: $primary;
  position: relative;
  bottom: 1px;
}

.ant-dropdown-menu-title-content {
  font-family: $txtFamily;
  font-weight: bolder;
  font-size: $large;
}
