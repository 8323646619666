$primary: #ea437a;
$secondary: #4269ab;
$lightBlue: #73B8C5;
$green: #1e5a50;
$white: #f4f3f2;
$hover-green: #cef0ea;
$bg: #fafafa;
$txt: #707070;
$txtRevert: #000;
$altTxt: #f7f1e1;
$gray: #707070;
